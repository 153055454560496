import React, { useContext } from "react";
import styles from "./Navbar.module.css";
import { Link, Outlet } from "react-router-dom";
import { lapContext } from "../../context/OverLapContext";

function Navbar() {
  const { isSuccess, SetIsSuccess } = useContext(lapContext);
  function CloseOverlap() {
    SetIsSuccess(false);
  }
  return (
    <div className={styles.navbarContainer}>
      <div className={isSuccess ? styles.overlap : styles.overlapBoxRemove}>
        <div className={styles.overlapBox}>
          <div>
            <p
              style={{ marginBottom: "20px", fontSize: "2rem", color: "white" }}
            >
              Email Send Success Fully
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={CloseOverlap}
                style={{ padding: "10px  20px", backgroundColor: "#e75810" }}
                type="button"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.navbarBox}>
        <header className={styles.header}>
          <Link className={styles.link} to="/">
            Home
          </Link>
          <div className={styles.marketing}>
            <Link className={styles.link} to="/emailmarketing">
              Email Marketing
            </Link>
          </div>
          <div className={styles.marketing}>
            <Link className={styles.link} to="/emailtxt">
              Email Text
            </Link>
          </div>
          <div className={styles.emailValid}>
            <Link className={styles.link} to="/emailvalidate">
              Email Validation
            </Link>
          </div>
        </header>
      </div>
      <div className={styles.restPage}>
        <Outlet></Outlet>
      </div>
    </div>
  );
}

export default Navbar;
