import React, { useContext, useState } from "react";
import styles from "./Email.module.css";
import { lapContext } from "../../context/OverLapContext";

function EmailMarketing() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("");
  const [btnStatus, setBtnStatus] = useState("Send");
  const { SetIsSuccess } = useContext(lapContext);
  const [inputStatus, setInputStatus] = useState(false);

  function getEmail(e) {
    setEmail(e.target.value);
  }

  function getSubject(e) {
    setSubject(e.target.value);
  }

  function getHtml(e) {
    setHtml(e.target.value);
  }

  async function formSubmit(e) {
    e.preventDefault();

    setBtnStatus("Sending....");
    setInputStatus(true);

    const response = await fetch(
      `https://artmadein-emailer-back.onrender.com/emailtemplate`,
      {
        method: "POST",
        body: JSON.stringify({ email, subject, html }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      setBtnStatus("Send");
      SetIsSuccess(true);
      setInputStatus(false);
      setEmail("");
    }

    // const resData = await response.json();
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.formbox}>
          <form onSubmit={formSubmit}>
            <div>
              <label htmlFor="email">Receiver Email Address</label>
              <input
                type="text"
                name="email"
                placeholder="Receiver Email Address"
                value={email}
                onChange={getEmail}
                disabled={inputStatus}
              ></input>
            </div>
            <div>
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={subject}
                onChange={getSubject}
                disabled={inputStatus}
              ></input>
            </div>
            <div>
              <label htmlFor="htmlcode">HTML Code</label>
              <textarea
                name="htmlcode"
                placeholder="Paste Your HTML Code"
                value={html}
                onChange={getHtml}
                disabled={inputStatus}
              ></textarea>
            </div>
            <button
              className={
                email.length === 0 || subject.length === 0 || html.length === 0
                  ? styles.btnDisabled
                  : styles.btn
              }
              disabled={
                email.length === 0 || subject.length === 0 || html.length === 0
              }
            >
              {btnStatus}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default EmailMarketing;
