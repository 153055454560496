import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./component/navbar/Navbar";
import Home from "./component/home/Home";
import EmailMarketing from "./component/email-marketing/EmailMarketing";
import EmailValidation from "./component/email-validation/EmailValidation";
import EmailTxt from "./component/email-txt/EmailTxt";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navbar></Navbar>}>
          <Route path="/" element={<Home></Home>}></Route>
          <Route
            path="/emailmarketing"
            element={<EmailMarketing></EmailMarketing>}
          ></Route>
          <Route path="/emailtxt" element={<EmailTxt></EmailTxt>}></Route>
          <Route
            path="/emailvalidate"
            element={<EmailValidation></EmailValidation>}
          ></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
