import React, { createContext, useState } from "react";

export const lapContext = createContext();

function OverLapContext({ children }) {
  const [isSuccess, SetIsSuccess] = useState(false);

  return (
    <lapContext.Provider value={{ isSuccess, SetIsSuccess }}>
      {children}
    </lapContext.Provider>
  );
}

export default OverLapContext;
