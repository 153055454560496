import React, { useState } from "react";
import styles from "./EmailTxt.module.css";

function EmailTxt() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [btnStatus, setBtnStatus] = useState("Send");
  const [inputStatus, setInputStatus] = useState(false);

  function toHandler(e) {
    setEmail(e.target.value);
  }

  function SubjectHandler(e) {
    setSubject(e.target.value);
  }

  function messageHandler(e) {
    setMessage(e.target.value);
  }

  async function submitHandler(e) {
    e.preventDefault();
    setBtnStatus("Sending...");
    setInputStatus(true);

    try {
      const response = await fetch(
        "https://artmadein-emailer-back.onrender.com/emailtxt",
        {
          method: "POST",
          body: JSON.stringify({ email, subject, message }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setEmail("");
        setBtnStatus("Send");
        setInputStatus(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
    <div className={styles.formbox}>
      <form className={styles.form} onSubmit={submitHandler}>
        <label htmlFor="to">To</label>
        <input
          type="text"
          name="to"
          placeholder="Receiver Email Address"
          value={email}
          onChange={toHandler}
          disabled={inputStatus}
        ></input>
        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          name="subject"
          value={subject}
          onChange={SubjectHandler}
          placeholder="Subject"
          disabled={inputStatus}
        ></input>
        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          value={message}
          onChange={messageHandler}
          disabled={inputStatus}
          placeholder="Write your Email"
        ></textarea>
        <button
          className={
            email.length === 0 || subject.length === 0 || message.length === 0
              ? styles.btnDisabled
              : styles.btn
          }
          disabled={
            inputStatus ||
            email.length === 0 ||
            subject.length === 0 ||
            message.length === 0
          }
        >
          {btnStatus}
        </button>
      </form>
    </div>
  );
}

export default EmailTxt;
