import React, { useState } from "react";
import styles from "./EmailValidation.module.css";

function EmailValidation() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(false);
  const [isvalid, setIsValid] = useState({
    valid: "",
    reason: "",
    disposable: "",
    disporeason: "",
    mx: "",
    mxreason: "",
    regex: "",
    regexreason: "",
    smtp: "",
    smtpreason: "",
    typo: "",
    typoreason: "",
  });

  // https://artmadein-emailer-back.onrender.com
  // http://localhost:4000

  // const [isValid, setIsValid] = useState();
  // const [isValidResponse, setIsValidResponse] = useState("");

  function emailHandler(e) {
    setEmail(e.target.value);
  }

  async function formSubmit(e) {
    e.preventDefault();
    setStatus(true);
    const response = await fetch("http://localhost:4000/emailvalidate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const result = await response.json();
    if (response.ok) {
      setStatus(false);
    }
    console.log(result);
    isvalid.valid = result.valid;
    isvalid.reason = result.reason;
    isvalid.disposable = result.validators.disposable.valid;
    isvalid.disporeason = result.validators.disposable.reason;
    isvalid.mx = result.validators.mx.valid;
    isvalid.mxreason = result.validators.mx.reason;
    isvalid.regex = result.validators.regex.valid;
    isvalid.regexreason = result.validators.regex.reason;
    isvalid.smtp = result.validators.smtp.valid;
    isvalid.smtpreason = result.validators.smtp.reason;
    isvalid.typo = result.validators.typo.valid;
    isvalid.typoreason = result.validators.typo.reason;
    setIsValid({ ...isvalid });
    // setIsValid(result.valid);
    // setIsValidResponse(result.reason);
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.secondContainer}>
          <form className={styles.form} onSubmit={formSubmit}>
            <input
              className={styles.input}
              type="text"
              placeholder="enter email"
              value={email}
              onChange={emailHandler}
            ></input>
            <button className={styles.button}>
              <p>{status ? "validating..." : "Validate"}</p>
            </button>
          </form>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Valid</th>
                  <th>Reason</th>
                  <th>Disposable</th>
                  <th>Disposable Reason</th>
                  <th>MX</th>
                  <th>MX Reason</th>
                  <th>Regex</th>
                  <th>Regex Reason</th>
                  <th>SMTP</th>
                  <th>SMTP Reason</th>
                  <th>Typo</th>
                  <th>Typo Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={
                      isvalid.valid ? styles.validtrue : styles.validfalse
                    }
                  >
                    {isvalid.valid ? "True" : "False"}
                  </td>
                  <td>{isvalid.reason}</td>
                  <td
                    className={
                      isvalid.disposable ? styles.validtrue : styles.validfalse
                    }
                  >
                    {isvalid.disposable ? "True" : "False"}
                  </td>
                  <td>{isvalid.disporeason}</td>
                  <td
                    className={
                      isvalid.mx ? styles.validtrue : styles.validfalse
                    }
                  >
                    {isvalid.mx ? "True" : "False"}
                  </td>
                  <td>{isvalid.mxreason}</td>
                  <td
                    className={
                      isvalid.regex ? styles.validtrue : styles.validfalse
                    }
                  >
                    {isvalid.regex ? "True" : "False"}
                  </td>
                  <td>{isvalid.regexreason}</td>
                  <td
                    className={
                      isvalid.smtp ? styles.validtrue : styles.validfalse
                    }
                  >
                    {isvalid.smtp ? "True" : "False"}
                  </td>
                  <td>{isvalid.smtpreason}</td>
                  <td
                    className={
                      isvalid.typo ? styles.validtrue : styles.validfalse
                    }
                  >
                    {isvalid.typo ? "True" : "False"}
                  </td>
                  <td>{isvalid.typoreason}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailValidation;
